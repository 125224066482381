import { Client as AccountClient } from './client/account';
import { Client as MainClient } from './client/main';
import { Client as UserClient } from './client/user';

/* eslint-disable dot-notation */

const orgClientMap = {}; // org id -> client configured for that organization
const client = {
    account(id) {
        const idx = `account_${id}`;
        if (!orgClientMap[idx]) {
            orgClientMap[idx] = new AccountClient({ endpoint: '/', serviceEndpoint: '/api/browser', accountId: id });
        }
        return orgClientMap[idx];
    },
    main() {
        if (!orgClientMap['main']) {
            orgClientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return orgClientMap['main'];
    },
    user(id) {
        const idx = `user_${id}`;
        if (!orgClientMap[idx]) {
            orgClientMap[idx] = new UserClient({ endpoint: '/', serviceEndpoint: '/api/browser', userId: id });
        }
        return orgClientMap[idx];
    },
};

export default client;
